/**
 * Generate a linear array of values
 * @param {Number} start
 * @param {Number} end
 * @param {Number} n
 * @returns {[]}
 */
export const linspace = (start = 0, end = 10, n = 10) => {
  const range = end - start;
  const increment = range / (n - 1);

  const result = [];
  for (let i = 0; i < n - 1; i++) {
    result.push(start + i * increment);
  }
  result.push(end);
  return result;
};

/**
 * Check to see if a number string has a given number of decimal points
 * @param {String} number
 * @param {Number} decimals
 * @return {Boolean}
 */
export const checkDecimalPlaces = (number, decimals) => {
  return getDecimalDigits(number) === decimals;
};

/**
 * Get the number of decimal digits in a number string
 * @param {String} number
 * @return {Number}
 */
export const getDecimalDigits = (number) => {
  const trimmed = trimNumber(number, true);
  const postDecimal = splitDecimal(trimmed)[1];
  return postDecimal.length;
};

/**
 * Check to see if a number string has a certain number of significant digits
 * @param {String} number
 * @param {Number} sigFigs
 * @return {Boolean}
 */
export const checkSigFigs = (number, sigFigs) => {
  return getSigDigits(number) === sigFigs;
};

/**
 * Get the number of significant digits in a number string
 * @param {String} number
 * @return {Number}
 */
export const getSigDigits = (number) => {
  let trimmed = trimNumber(number, true, true);

  if (!hasDecimal(trimmed)) {
    return trimmed.replace(/0+$/, '').length;
  } else {
    let splits = splitDecimal(trimmed);
    let preDecimal = splits[0].replace(/^0+/, '');
    let postDecimal = preDecimal.length ? splits[1] : splits[1].replace(/^0+/, '');
    return preDecimal.length + postDecimal.length;
  }
};

/**
 * Check if a number has a specific power of ten
 * @param {String} number
 * @param {Number} tenPower
 * @return {Boolean}
 */
export const checkTenPower = (number, tenPower) => {
  return getTenPower(number) === tenPower;
};

/**
 * Get the power of ten for a number
 * @param {String} number
 * @return {Number}
 */
export const getTenPower = (number) => {
  let trimmed = trimNumber(number, false, true);

  if (!hasTenPower(trimmed)) {
    return 0;
  } else {
    return parseInt(splitTenPower(trimmed)[1]);
  }
};

/**
 * Trim and standardize a number string such that it has no extra zeroes or spaces
 * @param {String} number
 * @param {Boolean} stripTenPower
 * @param {Boolean} stripNegatives
 * @return {String}
 */
export const trimNumber = (number, stripTenPower = false, stripNegatives = false) => {
  let trimmed = number.trim();
  const isNegative = number.substring(0, 1) === '-';
  trimmed = trimmed.replace(/^[0\-+]+/, '');
  if (trimmed === '') {
    trimmed = '0';
  }

  // Strip powers of ten
  const isTenPower = hasTenPower(number);
  if (isTenPower) {
    const [decimal, tenPower] = splitTenPower(trimmed);
    if (stripTenPower) {
      trimmed = decimal;
    } else {
      trimmed = decimal + 'e' + trimNumber(tenPower, true, false);
    }
  }

  // Leave prefixed zero if decimal
  if (trimmed.substring(0, 1) === '.') {
    trimmed = '0' + trimmed;
  }
  if (isNegative && !stripNegatives && trimmed !== '0') {
    trimmed = '-' + trimmed;
  }
  return trimmed;
};

/**
 * Check to see if a number string has a power of ten
 * @param {String} number
 * @returns {Boolean}
 */
export const hasTenPower = (number) => {
  return number.search(/[eE]/) !== -1;
};

/**
 * Check to see if a number string has a decimal
 * @param {String} number
 * @returns {Boolean}
 */
export const hasDecimal = (number) => {
  const decimal = splitTenPower(number)[0];
  return decimal.search(/\./) !== -1;
};

/**
 * Convert a string number to scientific notation
 * @param {String} number
 * @returns {string}
 */
export const toScientificNotation = (number) => {
  number = trimNumber(number);

  let [decimal, tenPower] = splitTenPower(number);
  let [preDecimal, postDecimal] = splitDecimal(decimal);
  let isNegative = preDecimal.substring(0, 1) === '-';
  preDecimal = trimNumber(preDecimal, false, true);
  if (preDecimal === '0') {
    while (preDecimal === '0' && postDecimal !== '') {
      preDecimal = postDecimal.substring(0, 1);
      postDecimal = postDecimal.substring(1);
      tenPower--;
    }
  } else {
    while (preDecimal.length > 1) {
      postDecimal = preDecimal.substring(preDecimal.length - 1) + postDecimal;
      preDecimal = preDecimal.substring(0, preDecimal.length - 1);
      tenPower++;
    }
  }

  // Standardizes all zeroes to be 0e0
  const isZero = preDecimal === '0' && postDecimal === '';
  if (isZero) {
    tenPower = 0;
    isNegative = false;
  }

  if (isNegative) {
    preDecimal = '-' + preDecimal;
  }
  if (postDecimal !== '') {
    postDecimal = '.' + postDecimal;
  }
  return preDecimal + postDecimal + 'e' + tenPower.toString();
};

/**
 * Checks to see if a string number is scientific notation
 * @param {String} number
 * @return {Boolean}
 */
export const isScientificNotation = (number) => {
  return toScientificNotation(number) === trimNumber(number);
};

/**
 * Split a number string into it's number and power of ten
 * @param {String} number
 * @return {String[]}
 */
export const splitTenPower = (number) => {
  if (hasTenPower(number)) {
    return number.split(/[Ee]/, 2);
  } else {
    return [number, '0'];
  }
};

/**
 * Split a decimal into the pre- and post-decimal digits
 * @param {String} number
 * @return {String[]}
 */
export const splitDecimal = (number) => {
  if (hasDecimal(number)) {
    return number.split(/\./, 2);
  } else {
    return [number, ''];
  }
};

/**
 * Check to see if two number strings are equal
 * @param {String} number1
 * @param {String} number2
 * @return {Boolean}
 */
export const isEqual = (number1, number2) => {
  number1 = toScientificNotation(number1);
  number2 = toScientificNotation(number2);
  return number1 === number2;
};

/**
 * Convert a number string to LaTeX formatted scientific notation
 * @param number
 * @return {string}
 */
export const toLatexScientificNotation = (number) => {
  const [preExponent, exponent] = splitTenPower(toScientificNotation(number));
  return `{${preExponent}} \\times 10^{${exponent}}`;
};
